import bowser from 'bowser';
import { config } from './config';

window.BB = bowser;

export default function deviceInfo()
{
	const ua = `${navigator.userAgent } Safari/604.1`;
	const browser = bowser.getParser(ua);

	let flag;

	if (browser.satisfies({ chrome: '>=0', chromium: '>=0' }))
		flag = 'chrome';
	else if (browser.satisfies({ firefox: '>=0' }))
		flag = 'firefox';
	else if (browser.satisfies({ safari: '>=0' }))
		flag = 'safari';
	else if (browser.satisfies({ opera: '>=0' }))
		flag = 'opera';
	else if (browser.satisfies({ 'microsoft edge': '>=0' }))
		flag = 'edge';
	else if (browser.getBrowserName(true) === 'safari')
		// 20231201 --改功能说明：新增支持iOS应用内打开
		flag = 'webkit';
	else if (browser.satisfies({ wechat: '>=0' }))
		if (browser.getOSName(true) === 'ios')
			// 20231202 --改功能说明：新增支持iOS WeChat应用内打开
			flag = 'wechat-webkit';
		else
			flag = 'wechat';
	else
		flag = 'unknown';

	// eslint-disable-next-line no-alert
	// alert(flag+browser.getBrowserVersion()+browser.getOSName(true));

	return {
		flag,
		os       : browser.getOSName(true), // ios, android, linux...
		platform : browser.getPlatformType(true), // mobile, desktop, tablet
		name     : browser.getBrowserName(true),
		version  : browser.getBrowserVersion(),
		bowser   : browser
	};
}
